.Article {
    .tlA {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .tlB {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .tlC {
        font-size: 1rem;
        font-weight: bold;
    }

    .tlD {
        font-style: italic;
    }

    .cphotothumb {
        img {
            width: 100%;
            height: 12vh;
        }
    }

    .biggerbadge {
        font-size: 1.5em;
    }
    .breakingnews {
        color: #ff0000;
        font-weight: bold;
        font-size: 2em;
        text-align: center;
    }
    .newstype {
        font-weight: bold;
    }
    .sidebar {
        .gallery-image {
            width: 100%;
            border: 1px solid #5f6368;
            padding: 3px;
            cursor: pointer;
        }

        .gallery-icon {
            margin: -3.5em 0 0 0.5em;
        }

    }
}