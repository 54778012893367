/*
.AppOuter {
    &::before {
        background-size: cover;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(http://vifogra.flowcenter.de/mfc/thumbnail.cgi?file=/flowmediastore/sources/Vifogra/testpic_029.jpg&width=800&height=600);
        background-repeat: repeat-y;
        background-attachment: fixed;
        filter: opacity(0.2) grayscale(1) blur(10px);
    }
}
*/

.App {
    /*
    filter: opacity(1);
    background-color: rgba(255,255,255,0.5);
    */

    &.container {
        box-shadow: 0 0 25px #ccc;
    }
}
