
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
  xxxl: 1560px
) !default;

$body-color: #212529;

/* $blue:    #0d6efd !default; */
$blue:    #000 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$primary:       $blue !default;

$link-color:                 #eee; //red
$link-hover-color:           #fff; //green
/*
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
*/

/*
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;
*/


$card-border-width: 0;
$card-inner-border-radius: 0;

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;

$card-color: $body-color;

$white: #fff;
$black: #000;

/*
$navbar-dark-color:                 rgba($white, .75) !default;
$navbar-dark-hover-color:           rgba($white, .90) !default;

$navbar-light-color:                rgba($black, .75) !default;
$navbar-light-hover-color:          rgba($black, .90) !default;

.bg-black {
  background-color: $black;
}
*/


// $card-spacer-y:                     $spacer !default;
// $card-spacer-x:                     $spacer !default;
// $card-title-spacer-y:               $spacer / 2 !default;
// $card-border-width:                 $border-width !default;
// $card-border-radius:                $border-radius !default;
// $card-border-color:                 rgba($black, .125) !default;
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
// $card-cap-padding-y:                $card-spacer-y / 2 !default;
// $card-cap-padding-x:                $card-spacer-x !default;
// $card-cap-bg:                       rgba($black, .03) !default;
// $card-cap-color:                    null !default;
// $card-height:                       null !default;
// $card-color:                        null !default;
// $card-bg:                           $white !default;
// $card-img-overlay-padding:          $spacer !default;
// $card-group-margin:                 $grid-gutter-width / 2 !default;

@import "../node_modules/bootstrap/scss/bootstrap";

/*
nav.bg-dark {
  background-color: #000 !important;
}
*/

.bg-dark {
  background-color: #000 !important;
}

/*
@media (max-width: 978px) {
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding:0;
    margin:0;
  }

  body {
    padding:0;
  }

  .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
    margin-left: 0;
    margin-right: 0;
    margin-bottom:0;
  }
}
*/