@import "../custom.scss";

.ArticleVCard {
    .card-body {
        .card-img-top {
            width: 100%;
            object-fit: cover;
        }

        p {
            margin-bottom: 0;
        }

        .card-title {
            font-weight: bold;
        }

        .dateline {
            font-size: 0.9rem;
            display: flex;
            /* justify-content:center; */

            .date { }

            .geo {
                /* margin-left:auto; */
            }
        }

        .shortd {
            --lh: 1.4rem;
            line-height: var(--lh);
            --max-lines: 3;
            position: relative;
            max-height: calc(var(--lh) * var(--max-lines));
            overflow: hidden;
            padding-right: 1rem; /* space for ellipsis */
        }

        .shortd::before {
            position: absolute;
            content: "...";
            /*   inset-block-end: 0;
            inset-inline-end: 0; */
            bottom: 0;
            right: 0;
        }

        .shortd::after {
            content: "";
            position: absolute;
            /*   inset-inline-end: 0; */
            right: 0;
            width: 1rem;
            height: 1rem;
            background: white;
        }
    }

    .card-img-top {
        border-bottom: 4px solid rgba($blue, 0);
    }

    a:hover {
        text-decoration: none;

        .card-body {
            color: $blue;
            transition: all 0.5s;
        }

        .card-img-top {
            border-bottom: 4px solid rgba($blue, 1);
            transition: all 0.5s;
        }
    }

    &.card {
        /* border: 1px solid rgba(0,0,0,0.1); */
        padding: 0.2rem;
        margin-bottom: 1rem;
        height: 98%;
    }
}


@include media-breakpoint-only(xxl) { 
    .ArticleVCard .card-img-top { height: 24vh; }
}

@include media-breakpoint-only(xl) { 
    .ArticleVCard .card-img-top { height: 24vh; }
}

@include media-breakpoint-only(lg) { 
    .ArticleVCard .card-img-top { height: 17vw; }
}

@include media-breakpoint-only(md) { 
    .ArticleVCard .card-img-top { height: 28vw; }
}

@include media-breakpoint-only(sm) { 
    .ArticleVCard .card-img-top { height: 50vw; }
}

@include media-breakpoint-only(xs) { 
    .ArticleVCard .card-img-top { height: 50vw; }
}

        /*

        @include media-breakpoint-up(md) { 
            .card-img-top {
                @media (min-width: 1200px) {
                    width: 100%;
                    height: 3vw;
                    object-fit: cover;
                }
            }
        }

        @include media-breakpoint-up(lg) { 
            .card-img-top {
                @media (min-width: 1200px) {
                    width: 100%;
                    height: 6vw;
                    object-fit: cover;
                }
            }
        }

        */