.Overlay {
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.78);
    position: fixed;
    z-index: 9999;
    align-items: center;
    transition: all 5s;

    .pointer {
        cursor: pointer;
    }

    & .inner {
        height: 95vh;
        width: 90vw;
        background: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        position: fixed;

        & .omedia {
            height: calc(100% - 7em);
            text-align: center;
            position: relative;

            & img, & video {
                max-height: 100%;
                left: 50%;
                top: calc(50% + 1em);
                transform: translate(-50%,-50%);
                position: absolute;
            }

            & svg {
                &:hover {
                    color: #fff;
                }

                &:first-of-type {
                    left: 0.25em;
                }

                &:last-of-type {
                    right: 0.25em;
                }

                box-shadow: 0 0 5px rgba(0,0,0,0.5);
                font-size: 3em;
                text-align: center;
                color: rgba(255,255,255,0.25);
                cursor: pointer;
                display: flex;
                align-items: center;
                position: absolute;
                top: 50%;
                border-radius: 2em;
                background-color: rgba(0,0,0,0.2);
            }
        }

        & .ometa {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5em;
            color: #e7e7e7;
            display: flex;
            flex-direction: row;

            & >div {
                width: 50%;
                overflow: hidden;
            }
        }
    }

    .darker {
        padding-top: 1em;
        background: rgba(0,0,0,0.8);
    }

    &.hide {
        display: none;
        opacity: 0;
    }

    &.show {
        display: flex;
        opacity: 1;
    }

    .prevnext {
        &:hover {
            color: #fff;
        }

        font-size: 3em;
        text-align: center;
        color: #939393;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .close {
        font-size: 3em;
        position: absolute;
        top: .5em;
        right: .75em;
        color: #fff !important;
        cursor: pointer;
    }

    .below {
        color: #eee;

        .padcol {
            padding: 1em;
        }

        .pointer {
            cursor: pointer;
        }
    }

}