.DownloadOverlay {
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.85);
    position: fixed;
    z-index: 9999;
    align-items: center;
    transition: all 5s;

    &.hide {
        display: none;
        opacity: 0;
    }

    &.show {
        display: flex;
        opacity: 1;
    }

    .dlform {
        border: 2px solid black;
        padding: 3rem;
        background-color: rgba(255,255,255,0.85);
        border-radius: 1em
    }

    .dlheader {
        color: #eee;
    }

    .close {
        font-size: 3em;
        position: absolute;
        top: .5em;
        right: .75em;
        color: #fff !important;
        cursor: pointer;
    }
}