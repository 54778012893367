.VideoPlayer {
    /*
    position: fixed;
    left: 1.5em;
    top: 1.5em;
    right: 1.5em;
    bottom: 1.5em;
    display: flex;
    flex-direction: column;
    */

    ul, li {
        list-style-type: none;
        padding: 0;
    }

    ul {
        margin: 0 0 0 0.5em;
    }

    li {
        margin: 0;
    }

    video {
        height: 100%;
    }

    .comments, .player {
        // height: 75%;
        height: 100%;
        background-color: rgba(0,0,0,0.65);
    }

    .comments {
        color: #fff;
        overflow-y: scroll;
    }
}