@import "../custom.scss";

.ArticleCarousel {
    .carousel-caption {
        top: 30px;
        left: 10%;
        text-align: left;
    }

    .carousel-item img {
        width: 100%;
        object-fit: cover;
    }
    
    .caroucaptioncontainer {
        text-shadow: 0 0 7px #000;
    }
}

@include media-breakpoint-only(xxl) { 
    .ArticleCarousel .carousel-item img { height: 60vh; }
}

@include media-breakpoint-only(xl) { 
    .ArticleCarousel .carousel-item img { height: 60vh; }
}

@include media-breakpoint-only(lg) { 
    .ArticleCarousel .carousel-item img { height: 60vh; }
}

@include media-breakpoint-only(md) { 
    .ArticleCarousel .carousel-item img { height: 50vw; }
}

@include media-breakpoint-only(sm) { 
    .ArticleCarousel .carousel-item img { height: 50vw; }
}

@include media-breakpoint-only(xs) { 
    .ArticleCarousel .carousel-item img { height: 50vw; }
}