.ClipHCard {
    .card-body {
        padding: 0;
    }

    .schot-title {
        font-size: 1rem;
    }

    li {
        list-style-type: disc;
    }

    .comments {
        overflow: hidden;
        max-height: 17vh;
        transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &.open {
            max-height: fit-content;
        }

        .more {
            padding: 0 0.3em;
            font-size: 1.2em;
            position: absolute;
            right: 0;
            bottom: 0;
            cursor: pointer;
            background: #fff;
            border-top-left-radius: 5px;
            border-style: solid none none solid;
            border-color: #f7f7f7;
        }
    }

    img.ClipImage {
        width: 100%;
        cursor: pointer;
    }
}
