.Header {
  .banner {
      width: 100%;
  }

  &.bannerbox {
      padding: 0 1px 0 0;
  }

  .navbar-brand {
    overflow: hidden;
  }

  .icon {
    font-size: 1.6em;
  }

  &.navbar {
    border-radius: 0 0 3px 3px;
  }

  &.nosticky .navbar-brand img.logo {
    margin-left: -100px;
    transition: all 1s ease-out;
  }

  &.sticky .navbar-brand img.logo {
    margin-left: 0;
    transition: all 1s ease-out;
  }

  .divider {
      margin: 0 1em;
  }

}
